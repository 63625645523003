<template>
  <div style="overflow-x:hidden !important">
    <!-- <AppNavbar v-if="showNav"/> -->
    <AppNavbar v-if="$route.name != 'riversedge-confirm-interest' && $route.name != 'confirm-interest' && $route.name != 'model-home-grand-opening' && $route.name != 'model-home-grand-opening-thankyou'"/>
    <nuxt />
  </div>
</template>

<script>
export default {
  components: {
    AppNavbar: () => import('@/components/AppNavbar'),
  },
  mounted() {

  },
  // computed: {
  //   showNav() {
  //     return this.$route.name !== 'confirm-interest' && this.$route.name !== 'riversedge-confirm-interest'
  //     // return this.$route.name != 'confirm-interest';
  //   }
  // }
}
</script>

<style>
html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}


</style>
